import React from 'react'
import { graphql } from 'gatsby' 
import { Container, Row, Col, Badge } from 'reactstrap'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Section from '../components/Section'
import PricingWidgetSection from '../components/PricingWidgetSection'
import BoxedCTASection from '../components/BoxedCTASection'
import Sidebar from '../components/Sidebar'

import './live.scss'



export default ({data}) => {
  const page = data.contentfulFacebookLive
  const { title, video } = page
  const summary = page.summary && page.summary.childMarkdownRemark && page.summary.childMarkdownRemark.html
  const relatedContent = data.relatedContent && data.relatedContent.edges.map(({ node }) => ({...node}))

  return (
    <Layout
      className="LivePage"
      title={`${title} - Live`}
    >
      <Hero>
        <Badge color="radical-red" className="mb-1" pill>Live</Badge>
        <h1>{title}</h1>
      </Hero>
      <Section>
        <Container>
          <Row>
            <Col lg={8}>
              {video && video.url &&
                <div className="embed-responsive embed-responsive-16by9 bg-light mb-3">
                  <iframe width="640" height="360" src={video.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" />
                </div>
              }
              {summary &&
                <div dangerouslySetInnerHTML={{ __html: summary }} />
              }
            </Col>

            <Col lg={4} className="pt-5 pt-lg-0">
              <Sidebar
                relatedContentTitle="Past Live Events"
                relatedContent={relatedContent}
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <BoxedCTASection />
      <PricingWidgetSection />
    </Layout>
  );
}

export const pageQuery = graphql`
  query LivePageByUrl($slug: String!) {
    contentfulFacebookLive(slug: { eq: $slug }) {
      ...ContentfulFacebookLiveFragment
    }
    relatedContent: allContentfulFacebookLive(filter: {slug: {ne: $slug}}, limit: 3, sort: {order: DESC, fields: created}) {
      edges {
        node {
          __typename
          ...ContentfulFacebookLiveFragment
        }
      }
    }
  }
`