import React from 'react';

import RelatedContentWidget from './RelatedContentWidget'
import SubscribeForm from './SubscribeForm'

import './Sidebar.scss'


const SubscribeWidget = () => (
  <div className="widget news-widget d-none d-lg-block">
    <h3 className="widget-title">JOIN THE HR REVOLUTION</h3>
    <p>Get all our latest resources, blogs & videos straight to your inbox.</p>
    <SubscribeForm buttonLabel="Do IT">
      <small>Join over 7,000 subscribers</small>
    </SubscribeForm>
  </div>
)



export default ({
  relatedContent,
  relatedContentTitle
}) =>
  <aside className="Sidebar">
    {relatedContent &&
      <RelatedContentWidget
        title={relatedContentTitle}
        items={relatedContent}
      />
    }
    <SubscribeWidget />
  </aside>